import {ApiBase} from '@jetCommon/api/base.js';

export default class VideoTutorialItemApi extends ApiBase {
    static resourceName = 'video_tutorial_items';

    updateOrder(data) {
        return this.apiPatch('order/', {
            video_tutorial_item_ids: data,
        });
    }
}
